/* global $ */
import "../../css/app/base/styles-dhc.scss";
class Appdhc
{
    constructor ()
    {
        this.initFooterMap();
        import(/* webpackChunkName: "slick" */ '../components/slick').then(() => {
            $('.product-child').slick({
                autoplay: false,
                arrows: true,
                infinite: true,
                slidesToShow: 4,
                speed: 1000,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }]
            });
        });
        console.log('Dove Hearing Centres initialized');
    }

    initFooterMap()
    {
        let _this = this;
        $('.footer-map')
            // tile mouse actions
            .on('mouseover', function(){
                _this.zoomInMap();
            })
            .on('mouseout', function(){
                _this.zoomOutMap();
            })

        $('.clinic-location')
            // tile mouse actions
            .on('mouseover', function(){
                let loc_position = $(this).attr('data-index');
                $('.loc-map-' + loc_position).addClass('active');
                $('.loc-marker').not('.loc-map-' + loc_position).addClass('dimmed');
            })
            .on('mouseout', function(){
                let loc_position = $(this).attr('data-index');
                $('.loc-map-' + loc_position).removeClass('active');
                $('.loc-marker').removeClass('dimmed');
            })

        $('.loc-marker')
            // tile mouse actions
            .on('mouseover', function(){
                $('.loc-marker').not(this).addClass('dimmed');
            })
            .on('mouseout', function(){
                $('.loc-marker').removeClass('dimmed');
            })
    }

    zoomInMap() {
        $('.footer-map .map-image').css({'transform': 'scale(4.5)', 'transform-origin': '72% 75%'});
        $('.footer-map .marker-block').removeClass('d-none');
    }

    zoomOutMap() {
        $('.footer-map .map-image').css({'transform': 'scale(1)'});
        $('.footer-map .marker-block').addClass('d-none');
    }
}

new Appdhc;